/* Layout.css */
.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .layout__content {
    flex: 1;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 0px;
    padding-right: 0px;
  }
  