/* Footer.css */

.footer {
    background-color: #333;
    color: #fff;
    padding: 2rem 1rem;
    text-align: center;
  }
  
  .footer__container {
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .footer__brand-link {
    font-size: 1.25rem;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    margin-bottom: 1rem;
    display: inline-block;
  }
  
  .footer__social {
    margin: 1rem 0;
  }
  
  .footer__social-link {
    color: #ddd;
    margin: 0 0.5rem;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
  }
  
  .footer__social-link:hover {
    color: #007acc;
  }
  
  .footer__info {
    font-size: 0.875rem;
    color: #aaa;
    margin-top: 1rem;
  }
  