/* LoginPage.css */
body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f8f9fa; /* Light gray background for simplicity */
  }
  
  .login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .login-container {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for a professional look */
  }
  
  .login-title {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
  }
  
  .login-label {
    font-size: 14px;
    color: #555555;
    margin-bottom: 5px;
  }
  
  .login-input {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .login-input:focus {
    border-color: #007bff; /* Blue border on focus */
    outline: none;
  }
  
  .login-button {
    padding: 10px;
    background-color: #007bff; /* Blue button */
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .login-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  .login-error {
    color: #e74c3c;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
  }
  