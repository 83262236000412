/* Projects.css */
.projects {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .projects__title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .projects__subtitle {
    font-size: 1.25rem;
    color: #555;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .projects__list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
  }
  
  .projects__card {
    background-color: #f9f9f9;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 300px;
    text-align: center;
  }
  
  .projects__card-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .projects__card-description {
    font-size: 1rem;
    color: #555;
    margin-bottom: 1rem;
  }
  
  .projects__technologies {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
  
  .projects__technology {
    background-color: #007acc;
    color: #fff;
    padding: 0.25rem 0.75rem;
    border-radius: 4px;
    font-size: 0.875rem;
  }
  
  .projects__link {
    color: #007acc;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .projects__link:hover {
    color: #005fa3;
  }
  