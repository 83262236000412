/* HighlightedName.css */

.highlighted-name {
    display: inline-flex;
    align-items: center;
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
    font-family: 'Arial', sans-serif;
    gap: 0.2rem;
  }
  
  .highlighted-name__text {
    display: flex;
    align-items: center;
  }
  
  .highlighted-letter {
    color: #007acc;
    font-size: 1.3rem; /* Ensure both highlighted letters have the same size */
    font-weight: bold; /* Add bold weight to match thickness */
    position: relative;
    transition: all 0.3s ease;
    padding: 0 0.1rem;
  }
  
  .highlighted-letter::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #007acc;
    transition: width 0.3s ease;
  }
  
  .highlighted-letter:hover::after {
    width: 120%;
  }
  
  .highlighted-acronym {
    font-size: 1.3rem; /* Match font size exactly */
    font-weight: bold; /* Match thickness exactly */
    margin-left: 0rem;
    color: #333;
    display: flex;
    align-items: center;
    gap: 0.1rem;
  }

  .highlighted-name {
    display: flex;
    align-items: center;
  }
  
  .highlighted-name__logo {
    width: 50px; /* Adjust the size as needed */
    height: 25px;
    margin-right: 10px; /* Space between the logo and text */
  }
  
  