.about-container {
    margin: 40px auto;
    max-width: 900px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.6;
  }
  
  .about-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    color: #333;
  }
  
  .about-intro {
    color: #555;
    margin-bottom: 40px;
  }
  
  .about-section-heading {
    margin-top: 40px;
    font-size: 1.5rem;
    color: #333;
    border-left: 4px solid #007bff;
    padding-left: 10px;
  }
  
  .job-cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  }
  
  .job-card {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  }
  
  .job-title {
    margin-top: 0;
    font-size: 1.2rem;
    color: #222;
    margin-bottom: 10px;
  }
  
  .job-list {
    list-style: disc inside;
    margin: 0;
    padding: 0;
  }
  
  .skills-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 10px;
    margin: 20px 0 0 0;
    padding: 0;
    list-style: disc inside;
  }
  
  .education-list,
  .publication-list {
    list-style: none;
    padding: 0;
    margin: 20px 0 0 0;
  }
  
  .education-item,
  .publication-item {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    margin-bottom: 10px;
  }
  
  .education-degree,
  .publication-title {
    margin: 0 0 5px 0;
    color: #222;
    font-weight: 600;
  }
  
  .about-footer {
    margin-top: 40px;
    text-align: center;
    color: #555;
  }

  /* Existing classes, if any, remain the same. */

/* Skills section container */
.skills-container {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  /* Each skill group is in its own card */
  .skill-group-card {
    flex: 1 1 calc(50% - 20px); 
    min-width: 280px; 
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 15px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  }
  
  /* Heading for the skill group */
  .skill-group-title {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: #333;
    border-left: 3px solid #007bff;
    padding-left: 10px;
  }
  
  /* List styling for skills */
  .skill-items {
    list-style: disc inside;
    margin: 0;
    padding: 0;
    line-height: 1.6;
  }
  
  /* Responsive: stack single-column on small screens */
  @media (max-width: 600px) {
    .skills-container {
      flex-direction: column;
    }
    
    .skill-group-card {
      flex: 1 1 100%;
    }
  }
  
  