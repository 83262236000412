/* Home.css */
/* Graph titles for Timeline and Daily Activity */
.home__graph-title {
  font-size: 0.9rem;
  font-weight: 600;
  color: #666;
  text-align: center;
  line-height: 1.5; /* Adjust line-height to control vertical spacing */
  letter-spacing: 0.05rem;
  max-width: 100%; /* Ensures it doesn’t exceed the container width */
  overflow-wrap: break-word; /* Prevents any long words from causing overflow */
}

.home {
  padding: 1.5rem;
  max-width: 1000px;
  margin: 0 auto;
}

.home__hero {
  text-align: center;
  margin-bottom: 1.5rem; /* Reduced bottom margin */
}

.home__title {
  font-size: 2.0rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.home__subtitle {
  font-size: 1.25rem;
  color: #555;
  margin-bottom: 1rem; /* Reduced bottom margin */
}

.home__section-title {
  font-size: 1.5rem; /* Slightly smaller font size */
  font-weight: bold;
  margin-bottom: 0.75rem; /* Reduced bottom margin */
}

.home__cta {
  display: inline-block;
  padding: 0.5rem 1rem; /* Reduced padding */
  background-color: #007acc;
  color: #fff;
  font-weight: bold;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.home__cta:hover {
  background-color: #005fa3;
}

.home__link {
  color: #007acc;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.home__link:hover {
  color: #005fa3;
}

.home__about {
  text-align: center;
  margin-bottom: 1.5rem; /* Reduced bottom margin */
}

.home__about-text {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1rem; /* Reduced bottom margin */
}

/* Project Section */
.home__projects {
  text-align: center;
}

.home__project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px; /* Ensures even spacing between rows and columns */
  margin-top: 20px;
  padding-bottom: 20px; /* Extra space at the bottom of grid */
}

.home__project-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  text-align: center;
  transition: transform 0.3s;
  position: relative;
  overflow: hidden;
  min-height: 250px; /* Ensures all cards maintain a minimum height */
  box-sizing: border-box; /* Prevents padding from affecting overall size */
}

.home__project-card:hover {
  transform: scale(1.05);
}

.home__project-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.home__project-description {
  font-size: 1rem;
  color: #555;
  flex-grow: 1; /* Pushes content to fill space above buttons */
  margin-bottom: 1rem;
}

.home__project-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: auto; /* Ensures buttons stay at the bottom */
}

.home__project-link {
  color: #007acc;
  background-color: #e0f7ff;
  padding: 8px 12px;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.home__project-link:hover {
  color: #005fa3;
  background-color: #cfe6ff;
}

/* Responsive Design */
@media (max-width: 768px) {
  .home__project-grid {
    grid-template-columns: 1fr; /* Stacks projects in a single column on small screens */
    gap: 15px; /* Reduces gap slightly for smaller screens */
  }

  .home__project-card {
    padding: 1rem;
  }

  .home__section-title {
    font-size: 1.25rem; /* Slightly smaller font for mobile */
  }

  .home__title {
    font-size: 1.75rem; /* Adjust title font size for mobile */
  }

  .home__subtitle {
    font-size: 1rem;
  }
}

/* Timeline and Daily Activity styling */
.home__timeline-activity {
  display: flex;
  flex-direction: column;
  gap: 0.5rem; /* Reduced gap between Timeline and DailyActivityBar */
  margin-bottom: 1.5rem; /* Reduced bottom margin */
  padding-left: 0px;
}

.home__timeline-activity > * {
  width: 100%;
  margin-bottom: 0.5rem; /* Reduced individual bottom margin */
  padding-left: 0px;
}

/* Skills Section Styles */
.home__skills {
  padding: 50px 20px;
  background-color: #f9f9f9;
}

.home__skills-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.skill-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px; /* Adjust as needed */
}

.skill-icon {
  font-size: 2rem;
  color: #007acc;
  cursor: pointer;
  transition: color 0.3s;
}

.skill-icon:hover {
  color: #005fa3;
}

.skill-name {
  margin-top: 10px;
  font-size: 1rem;
  color: #333;
  cursor: pointer;
  transition: color 0.3s;
}

.skill-name:hover {
  color: #007acc;
}
