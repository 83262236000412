/* Header.css */

.header {
    background-color: #fff;
    padding: 1rem 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 10;
}
  
.header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}
  
.header__logo-link {
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
    color: #333;
}
  
.highlight {
    text-decoration: underline;
    color: #007acc; /* Change color as desired */
}

/* Default styles for desktop navigation */
.header__nav-list {
    display: flex;
    gap: 1.5rem;
    list-style: none;
}

.header__nav-link {
    text-decoration: none;
    color: #333;
    font-weight: 500;
    transition: color 0.3s ease;
}

.header__nav-link:hover {
    color: #007acc;
}

/* Hamburger Icon */
.header__hamburger {
    display: none;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    color: #333;
}

/* Mobile Styles */
@media (max-width: 768px) {
    /* Show hamburger icon and hide nav links by default */
    .header__hamburger {
        display: block;
    }

    .header__nav {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 1rem 0;
        text-align: center;
        z-index: 5;
    }

    /* Show navigation when menu is open */
    .header__nav--open {
        display: flex;
    }

    .header__nav-item {
        margin: 0.5rem 0;
    }

    .header__nav-link {
        font-size: 1.1rem;
    }
}
