.contact-container {
    margin: 40px auto;
    max-width: 800px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .contact-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    color: #333;
  }
  
  .contact-intro {
    text-align: center;
    color: #555;
    line-height: 1.6;
    margin-bottom: 40px;
  }
  
  .contact-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
  }
  
  .contact-card {
    flex: 1 1 calc(50% - 20px); /* 2 items per row, minus the gap */
    min-width: 250px;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  }
  
  .contact-section-title {
    margin: 0;
    margin-bottom: 10px;
    font-weight: 600;
    color: #222;
    font-size: 1.2rem;
  }
  
  .contact-section-content {
    margin: 0;
    color: #555;
    line-height: 1.6;
    font-size: 1rem;
  }
  
  .contact-link,
  .linkedin-link {
    text-decoration: none;
    color: #007bff;
    font-weight: 500;
  }
  
  .linkedin-link {
    color: #0077b5;
  }
  
  .footer-paragraph {
    text-align: center;
    margin-top: 40px;
    color: #555;
    line-height: 1.6;
  }
  
  @media (max-width: 600px) {
    .contact-row {
      flex-direction: column;
    }
  
    .contact-card {
      flex: 1 1 100%;
      margin-bottom: 20px;
    }
  }
  