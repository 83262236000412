/* ProtectedPage.css */
.protected-page {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h2 {
    text-align: left;
    color: #333;
    margin-bottom: 20px;
  }
  
  .data-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    background-color: #f9f9f9;
  }
  
  .data-table th,
  .data-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .data-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .data-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .data-table tr:hover {
    background-color: #e9e9e9;
  }
  
  .data-table th input {
    width: 90%;
    margin-top: 5px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropbtn {
    background-color: #f1f1f1;
    color: black;
    padding: 5px 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    padding: 10px;
    border: 1px solid #ddd;
    z-index: 1;
  }
  
  .dropdown-content label {
    display: block;
    padding: 5px 0;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  

  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropbtn {
    background-color: #f1f1f1;
    color: black;
    padding: 5px 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    padding: 10px;
    border: 1px solid #ddd;
    z-index: 1;
  }
  
  .dropdown-content label {
    display: block;
    padding: 5px 0;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }

  /* .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  } */
  
  .logout-button {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .logout-button:hover {
    background-color: #ff1a1a;
  }
  
  